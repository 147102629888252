import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { jSith } from "../../../../util/jquery-replacement";
import { LineItemI } from "../../../../pojo/Pricing";
import { PricingService } from "../../../../services/pricing.service";
import { TypeManagerDecorator } from "../../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-pricing-subtotal-charges")
@Component({
    selector: "s25-ng-pricing-subtotal-charges",
    template: `<div *ngIf="init">
        <div
            *ngIf="sumProfileOccAdjustments !== undefined"
            class="c-margin-bottom--half pricing-tooltip-wrapper"
            tabindex="0"
            (mouseenter)="toggleTooltip()"
            (mouseleave)="toggleTooltip()"
            (focus)="toggleTooltip()"
            (blur)="toggleTooltip()"
        >
            <div>
                {{ PricingService.formatCurrency(sumProfileOccAdjustments)
                }}<span class="c-margin-left--quarter">
                    <svg class="c-svgIcon">
                        <title>Hide Line Item Row</title>
                        <use
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#info"
                        ></use>
                    </svg>
                </span>
            </div>
            <div class="tooltip-message" [ngClass]="{ 'hide-tooltip': showTooltip }">
                <h4>{{ tooltipHeader }}</h4>
                <p>{{ tooltipMessage }}</p>
            </div>
        </div>

        <div *ngFor="let item of adjustments" class="c-margin-bottom--half">
            <span>{{ item.total_charge | currency }}</span>
        </div>
        <div *ngIf="modelBean.profileTax">{{ modelBean.profileTax }}</div>
    </div>`,
    styles: `
        :host ::ng-deep .c-svgIcon {
            position: relative;
            bottom: 1px;
        }

        .pricing-tooltip-wrapper {
            display: flex;
            position: relative;
        }

        .tooltip-message {
            opacity: 0;
            transition:
                opacity 1s,
                color 0s;
            position: absolute;
            bottom: 15%;
            left: 10.9em;
            width: 15em;
            font-size: 0.9em;
            color: #fff;
            z-index: -1;
        }

        .tooltip-message > p {
            text-wrap: balance;
        }

        .tooltip-message.hide-tooltip {
            opacity: 1;
            background-color: #fff;
            z-index: 1;
            padding: 10px;
            border: 1px solid darkgrey;
            border-radius: 5px;
            position: absolute;
            bottom: -11.5em;
            left: 8.75em;
            width: 15em;
            font-size: 0.9em;
            color: #000;
        }

        .tooltip-message.hide-tooltip::before,
        .tooltip-message.hide-tooltip::after {
            content: "";
            width: 0;
            height: 0;
            border-top: 7px solid transparent;
            border-right: 15px solid #fff;
            border-bottom: 7px solid transparent;
            display: inline-block;
            position: absolute;
            left: -14px;
            top: 13px;
        }

        .tooltip-message.hide-tooltip::before {
            border-right: 16px solid #000;
            left: -16px;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25PricingSubtotalChargesComponent implements OnInit {
    @Input() modelBean: any;

    init: boolean;
    adjustments: any = [];
    sumProfileOccAdjustments: number;
    showTooltip: boolean;
    tooltipMessage: string =
        "The total for all occurrence adjustments is added to the subtotal of pre-adjustment occurrences as well as adjustments to items not associated with occurrences to arrive at a total for the organization.";
    tooltipHeader = "Net Total Adjustments";

    constructor(private cd: ChangeDetectorRef) {}

    ngOnInit() {
        if (this.modelBean.occView) {
            const nonOccAdjustments = PricingService.agg.adjustments.subtotal(
                this.modelBean.noOccItems.filter((item: LineItemI) => item.bill_item_type_id !== 2),
            );

            this.sumProfileOccAdjustments =
                this.modelBean.profileSubtotals.reduce(
                    (sum: number, item: LineItemI) => sum + item.profileAdjustments,
                    0,
                ) - nonOccAdjustments;
        }

        jSith.forEach(this.modelBean.allBillItems, (_, billItem) => {
            //passed in by pricing-service footerF supplied to getPricingOrganizationListFn
            if (billItem.bill_item_type_id === -1 && !isNaN(parseFloat(billItem.adjustment_amt))) {
                this.adjustments.push(billItem);
            }
        });

        jSith.forEach(this.modelBean.allBillItems, (_, billItem) => {
            if (billItem.bill_item_type_id === -1 && !isNaN(parseFloat(billItem.adjustment_percent))) {
                this.adjustments.push(billItem);
            }
        });

        this.init = true;
    }

    toggleTooltip() {
        this.showTooltip = !this.showTooltip;
        this.cd.detectChanges();
    }

    protected readonly PricingService = PricingService;
}
