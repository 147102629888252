import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { TypeManagerDecorator } from "../../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-pricing-tooltip")
@Component({
    selector: "s25-ng-pricing-tooltip",
    template: `<div *ngIf="init && data.value !== '$0.00'" class="pricing-tooltip-wrapper c-margin-bottom--single">
        <div
            class="tooltip-amount"
            tabindex="0"
            [attr.aria-label]="data.value + ' ' + message"
            (mouseenter)="toggleTooltip()"
            (mouseleave)="toggleTooltip()"
            (focus)="toggleTooltip()"
            (blur)="toggleTooltip()"
        >
            {{ data.value }}
            <span class="c-margin-left--quarter">
                <svg class="c-svgIcon">
                    <title>Hide Line Item Row</title>
                    <use
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#info"
                    ></use>
                </svg>
            </span>
        </div>
        <div class="tooltip-message" [ngClass]="{ 'hide-tooltip': showTooltip }">
            <h4>{{ messageHeader }}</h4>
            <p>{{ message }}</p>
        </div>
    </div>`,
    styles: `
        :host ::ng-deep .c-svgIcon {
            position: relative;
            bottom: 1px;
        }

        .pricing-tooltip-wrapper {
            display: flex;
            position: relative;
        }

        .tooltip-message {
            opacity: 0;
            transition:
                opacity 1s,
                color 0s;
            position: absolute;
            bottom: 15%;
            left: 10.9em;
            width: 15em;
            font-size: 0.9em;
            color: #fff;
            z-index: -1;
        }

        .tooltip-message > p {
            text-wrap: balance;
        }

        .tooltip-message.hide-tooltip {
            opacity: 1;
            background-color: #fff;
            z-index: 1;
            padding: 10px;
            border: 1px solid darkgrey;
            border-radius: 5px;
            position: absolute;
            bottom: -9.5em;
            left: 7em;
            width: 20em;
            font-size: 0.9em;
            color: #000;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        }

        .tooltip-message.hide-tooltip::before,
        .tooltip-message.hide-tooltip::after {
            content: "";
            width: 0;
            height: 0;
            border-top: 7px solid transparent;
            border-right: 15px solid #fff;
            border-bottom: 7px solid transparent;
            display: inline-block;
            position: absolute;
            left: -14px;
            top: 13px;
        }

        .tooltip-message.hide-tooltip::before {
            border-right: 16px solid #000;
            left: -16px;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25PricingTooltipComponent implements OnInit {
    @Input() data: { type: "listPrice" | "tax"; value: string };

    message: string;
    messageHeader: string;
    init: boolean;
    showTooltip: boolean;

    constructor(private cd: ChangeDetectorRef) {}

    ngOnInit() {
        if (this.data.type === "listPrice") {
            this.message =
                "The total for all occurrence adjustments is added to the subtotal of pre-adjustment Occurrences as well as the list prices for items not associated with occurrences to arrive at a total list price for the organization.";
            this.messageHeader = "Total Occurrence Adjustments";
        } else {
            this.message =
                "Tax amounts for items associated with occurrences are added to the sum of taxes for items not associated with occurrences to arrive at a total tax amount for the organization.";
            this.messageHeader = "Total Tax for Occurrence Items";
        }

        this.init = true;
    }

    toggleTooltip() {
        this.showTooltip = !this.showTooltip;
        this.cd.detectChanges();
    }
}
