import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class BalanceUpdateService {
    private balance = new BehaviorSubject<BalanceData>(null);

    constructor() {}

    updateBalance(balance: BalanceData) {
        this.balance.next(balance);
    }

    getBalance() {
        return this.balance.asObservable();
    }
}

export type BalanceData = {
    amount: number;
    orgId: number;
};
