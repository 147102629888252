import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from "@angular/core";
import { S25Util } from "../../../../util/s25-util";
import { TypeManagerDecorator } from "../../../../main/type.map.service";
import { S25LoadingApi } from "../../../s25-loading/loading.api";
import { PricingService } from "../../../../services/pricing.service";
import { BalanceUpdateService } from "./balance.update.service";

@TypeManagerDecorator("s25-ng-pricing-organization")
@Component({
    selector: "s25-ng-pricing-organization",
    template: `<div *ngIf="init">
        <s25-loading-inline [model]="{}"></s25-loading-inline>
        <div *ngIf="isBalanceDisplay" class="balance-data">
            <div class="balance-header">Outstanding Balance:</div>
            <div>{{ modelBean.remainingBalance | currency }}</div>
        </div>
        <div
            *ngIf="
                !isBalanceDisplay &&
                modelBean.canEdit &&
                modelBean.canEditPricing &&
                orgList.length > 1 &&
                !modelBean.listItems
            "
        >
            <select
                name="organizations"
                class="cn-form__control"
                aria-label="Select Charge To Organization"
                [(ngModel)]="modelBean.selectedOrg"
                (change)="orgChange()"
            >
                <option *ngFor="let org of orgList" [ngValue]="org">{{ org.organization_name }}</option>
            </select>
        </div>
        <span
            *ngIf="
                !isBalanceDisplay &&
                !modelBean.listItems &&
                (!modelBean.canEdit || !modelBean.canEditPricing || orgList.length <= 1)
            "
            >{{ modelBean.itemName || this.modelBean.selectedOrg?.organization_name }}</span
        >
    </div>`,
    styles: `
        .balance-data {
            text-align: center;
        }

        .balance-data div:first-child {
            font-size: 90%;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25PricingOrganizationComponent implements OnInit {
    @Input() modelBean: any;

    @Output() updateOrg: EventEmitter<any> = new EventEmitter<any>();

    init: boolean;
    orgList: any;
    isBalanceDisplay: boolean;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private balanceUpdateService: BalanceUpdateService,
    ) {}

    ngOnInit() {
        this.isBalanceDisplay =
            S25Util.isDefined(this.modelBean.remainingBalance) && this.modelBean.remainingBalance >= 0;

        if (this.isBalanceDisplay) {
            this.balanceUpdateService.getBalance().subscribe((balance) => {
                if (balance?.orgId === this.modelBean.orgId) {
                    this.updateBalanceView(balance?.amount);
                }
            });
        }

        if (this.modelBean && !this.isBalanceDisplay) {
            const { charge_to_name, allOrgs, charge_to_id } = this.modelBean;

            this.modelBean.itemName = charge_to_name;
            this.orgList = allOrgs;

            if (this.orgList?.length > 0) {
                this.modelBean.selectedOrg =
                    S25Util.array.getByProp(this.orgList, "organization_id", charge_to_id) || this.orgList[0];
            }
        }

        this.init = true;
    }

    async orgChange() {
        S25LoadingApi.init(this.elementRef.nativeElement);

        const {
            selectedOrg,
            eventData,
            eventId,
            bill_item_id,
            bill_item_type_id,
            ev_dt_profile_id,
            evBillId,
            profileId,
        } = this.modelBean;

        this.modelBean.itemName = selectedOrg.organization_id;

        try {
            const pricingData = await PricingService.putPricingLineItemChargeToId(
                eventId,
                selectedOrg.organization_id,
                bill_item_id,
                bill_item_type_id,
                ev_dt_profile_id ?? profileId,
                evBillId,
            );

            const billItems = S25Util.propertyGet(eventData, "bill_item");

            billItems.forEach((item: any) => {
                if (
                    parseInt(item.bill_item_id) === parseInt(bill_item_id) &&
                    parseInt(item.bill_item_type_id) === parseInt(bill_item_type_id) &&
                    parseInt(item.ev_dt_profile_id) === parseInt(ev_dt_profile_id)
                ) {
                    item.charge_to_id = parseInt(selectedOrg.organization_id);
                    item.charge_to_name = selectedOrg.organization_name;
                    return;
                }
            });

            pricingData.newChargeToId = this.modelBean.selectedOrg.organization_id;
            const update = PricingService.processTableUpdate(pricingData, this.modelBean);

            this.updateOrg.emit(update);

            S25LoadingApi.destroy(this.elementRef.nativeElement);
        } catch (error) {
            S25LoadingApi.destroy(this.elementRef.nativeElement);
            console.error(error);
            alert("There was an error while saving the organization to the bill item");
        }
    }

    updateBalanceView(paymentAmt: number) {
        this.modelBean.totalPayments += paymentAmt * 100;
        this.modelBean.remainingBalance = this.modelBean.remainingBalance - paymentAmt;
        this.cd.detectChanges();
    }
}
