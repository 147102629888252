import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from "@angular/core";
import { ReportService } from "../../../../services/report.service";
import { PricingModel } from "../../../../pojo/Pricing";
import { OrganizationI } from "../../../../pojo/OrganizationI";
import { S25LoadingApi } from "../../../s25-loading/loading.api";
import { TypeManagerDecorator } from "../../../../main/type.map.service";
import { TelemetryService } from "../../../../services/telemetry.service";

@TypeManagerDecorator("s25-ng-pricing-org-summary")
@Component({
    selector: "s25-ng-pricing-org-summary",
    template: `<table *ngIf="init" class="tblOrgSummary ngTblOrgSummary">
        <tbody>
            <tr>
                <th *ngIf="modelBean.hasFreshbooks">Organizations Invoiced</th>
                <td *ngIf="modelBean.hasFreshbooks" class="hide-on-mobile"></td>
                <th *ngIf="modelBean.hasFreshbooks">Organizations Not Invoiced</th>
                <td *ngIf="modelBean.hasFreshbooks" class="hide-on-mobile"></td>
                <th *ngIf="modelBean.hasFreshbooks">Organizations Without Charge</th>
                <td *ngIf="modelBean.hasFreshbooks" class="hide-on-mobile"></td>
                <th>Print Invoice for Event</th>
            </tr>
            <tr>
                <td *ngIf="modelBean.hasFreshbooks" class="tblOrgSummaryContent">
                    <span *ngIf="invoiceOrgItems.length === 0">{{ modelBean.lang.none }}</span>
                    <span *ngFor="let org of invoiceOrgItems; last as isLast">
                        <a (click)="scrollTo(org.organization_id)"
                            >{{ org.organization_name }}{{ !isLast ? ", " : "" }}</a
                        >
                    </span>
                </td>
                <td *ngIf="modelBean.hasFreshbooks" class="hide-on-mobile"></td>
                <td *ngIf="modelBean.hasFreshbooks" class="tblOrgSummaryContent">
                    <span *ngIf="billedOrgItems.length === 0">{{ modelBean.lang.none }}</span>
                    <span *ngFor="let org of billedOrgItems; last as isLast">
                        <a (click)="scrollTo(org.organization_id)"
                            >{{ org.organization_name }}{{ !isLast ? ", " : "" }}</a
                        >
                    </span>
                </td>
                <td *ngIf="modelBean.hasFreshbooks" class="hide-on-mobile"></td>
                <td *ngIf="modelBean.hasFreshbooks" class="tblOrgSummaryContent">
                    <span *ngIf="noChargeOrgItems.length === 0">{{ modelBean.lang.none }}</span>
                    <span *ngFor="let org of noChargeOrgItems; last as isLast"
                        >{{ org.organization_name }}{{ !isLast ? ", " : "" }}</span
                    >
                </td>
                <td *ngIf="modelBean.hasFreshbooks" class="hide-on-mobile"></td>
                <td class="tblOrgSummaryContent">
                    <s25-loading-inline [model]="{}"></s25-loading-inline>
                    <select class="cn-form__control" [(ngModel)]="selectedInvoiceId" (change)="printInvoice()">
                        <option *ngIf="!selectedInvoiceId" [value]="undefined">Select Invoice</option>
                        <option *ngFor="let invoice of modelBean.invoices" [value]="invoice.id">
                            {{ invoice.billDefn.billName }}
                        </option>
                    </select>
                </td>
            </tr>
        </tbody>
    </table>`,
    styles: `
        .tblOrgSummary.ngTblOrgSummary {
            a {
                color: #2573a7 !important;
            }

            .tblOrgSummaryContent:has(select) {
                padding: 6px;
            }

            s25-loading-inline {
                margin: unset;
            }
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25PricingOrgSummaryComponent implements OnInit {
    @Input() modelBean: PricingModel;

    @Output() signalScroll: EventEmitter<string> = new EventEmitter<string>();

    init: boolean = false;
    invoiceOrgItems: OrganizationI[];
    billedOrgItems: OrganizationI[];
    noChargeOrgItems: OrganizationI[];
    selectedInvoiceId: number;

    constructor(
        private cd: ChangeDetectorRef,
        private elementRef: ElementRef,
    ) {}

    ngOnInit() {
        this.invoiceOrgItems = this.modelBean.allOrgs.filter((org: OrganizationI) => {
            return this.modelBean.invoiceOrgs.indexOf(org.organization_id) > -1;
        });

        this.billedOrgItems = this.modelBean.allOrgs.filter((org: OrganizationI) => {
            return (
                this.modelBean.billedOrgs.indexOf(org.organization_id) > -1 &&
                this.modelBean.invoiceOrgs.indexOf(org.organization_id) === -1
            );
        });

        this.noChargeOrgItems = this.modelBean.allOrgs.filter((org: OrganizationI) => {
            return this.modelBean.billedOrgs.indexOf(org.organization_id) === -1;
        });

        this.init = true;

        this.cd.detectChanges();
    }

    scrollTo(orgId: number | string) {
        this.signalScroll.emit(`#pricing_org_list_${this.modelBean.eventId}_${orgId}`);
    }

    async printInvoice() {
        TelemetryService.sendWithSub("Pricing", "Event", "PrintInvoice");
        S25LoadingApi.init(this.elementRef.nativeElement);
        await ReportService.invoiceReport(
            this.modelBean.eventId,
            this.selectedInvoiceId == -999 ? null : this.selectedInvoiceId,
        );
        S25LoadingApi.destroy(this.elementRef.nativeElement);
        this.cd.detectChanges();
    }
}
