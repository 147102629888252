import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { jSith } from "../../../../util/jquery-replacement";
import { PricingService } from "../../../../services/pricing.service";
import { LineItemI, PricingOccurrence } from "../../../../pojo/Pricing";
import { S25Util } from "../../../../util/s25-util";
import { TypeManagerDecorator } from "../../../../main/type.map.service";
import { S25ModalComponent } from "../../../s25-modal/s25.modal.component";
import { ModalData } from "../../../modal/modal.service";

@TypeManagerDecorator("s25-ng-pricing-adjustment")
@Component({
    selector: "s25-ng-pricing-adjustment",
    template: `<div *ngIf="init">
        <!-- Footer adjustment row--->
        <ng-container *ngIf="!modelBean.bill_item_id">
            <div class="pricing-tooltip-wrapper">
                <div
                    *ngIf="sumProfileOccAdjustments !== undefined"
                    class="c-margin-bottom--half tooltip-amount"
                    tabindex="0"
                    (mouseenter)="toggleTooltip()"
                    (mouseleave)="toggleTooltip()"
                    (focusin)="toggleTooltip()"
                    (focusout)="toggleTooltip()"
                >
                    <div>
                        {{ PricingService.formatCurrency(sumProfileOccAdjustments)
                        }}<span class="c-margin-left--quarter">
                            <svg class="c-svgIcon">
                                <title>Hide Line Item Row</title>
                                <use
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#info"
                                ></use>
                            </svg>
                        </span>
                    </div>
                </div>
                <div class="tooltip-message" [ngClass]="{ 'hide-tooltip': showTooltip }">
                    <h4>{{ tooltipHeader }}</h4>
                    <p>{{ tooltipMessage }}</p>
                </div>
            </div>

            <div
                *ngFor="let item of dollarAdjustments"
                class="c-margin-bottom--half adj-item-wrapper"
                (click)="editAdjustment(item, false)"
                (keydown.enter)="editAdjustment(item, false)"
                tabindex="0"
                aria-label="Edit Adjustment"
            >
                <div *ngIf="item.adjustment_name" class="PricingTaxName">{{ item.adjustment_name }}</div>
                <span>{{ item.adjustment_amt | currency }}</span>
                <span *ngIf="modelBean.canAdjust && modelBean.canEditPricing" class="edit-icon c-margin-left--half">
                    <svg class="c-svgIcon">
                        <title>Edit Adjustment</title>
                        <use
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#edit"
                        ></use>
                    </svg>
                </span>
            </div>
            <div
                *ngFor="let item of percAdjustments"
                class="c-margin-bottom--half adj-item-wrapper adj-item-wrapper"
                (click)="editAdjustment(item, false)"
                (keydown.enter)="editAdjustment(item, false)"
                tabindex="0"
                aria-label="Edit Adjustment"
            >
                <div *ngIf="item.adjustment_name" class="PricingTaxName">{{ item.adjustment_name }}</div>
                <span>{{ item.adjustment_percent }}</span>
                <!--<a class="EditInline aw-button aw-button--outline" (click)="editAdjustment(item, false)">EDIT</a>--->
                <span *ngIf="modelBean.canAdjust && modelBean.canEditPricing" class="edit-icon c-margin-left--half">
                    <svg class="c-svgIcon">
                        <title>Edit Adjustment</title>
                        <use
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#edit"
                        ></use>
                    </svg>
                    <!--<button class="EditInline aw-button aw-button--outline" (click)="editAdjustment()">EDIT</button>--->
                </span>
            </div>
            <div *ngIf="modelBean.canAdjust && modelBean.canEditPricing">
                <!--<a class="EditInline aw-button aw-button--outline" (click)="editAdjustment({}, true)">NEW</a>--->
                <button class="aw-button aw-button--outline c-margin-top--half" (click)="editAdjustment({}, true)">
                    <svg class="c-svgIcon">
                        <title>Add Adjustment</title>
                        <use
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#plus"
                        ></use>
                    </svg>
                    <span>Add</span>
                </button>
            </div>
        </ng-container>
        <!-- Line item adjustment --->
        <ng-container *ngIf="modelBean.bill_item_id && !modelBean.subtotal">
            <div
                *ngIf="modelBean.canEdit && modelBean.canEditPricing && cellHasData"
                (click)="editAdjustment()"
                (keydown.enter)="editAdjustment()"
                tabindex="0"
                aria-label="Edit Adjustment"
                class="adj-item-wrapper"
            >
                <div *ngIf="modelBean.adjustment_name" class="PricingTaxName adj-item">
                    {{ modelBean.adjustment_name }}
                </div>
                <span *ngIf="modelBean.adjustment_amt" class="adj-entry">{{
                    modelBean.adjustment_amt | currency
                }}</span>
                <span *ngIf="modelBean.adjustment_percent" class="adj-entry">{{ modelBean.adjustment_percent }}</span>
                <span class="edit-icon c-margin-left--half">
                    <svg class="c-svgIcon">
                        <title>Edit Adjustment</title>
                        <use
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#edit"
                        ></use>
                    </svg>
                </span>
            </div>

            <button
                *ngIf="modelBean.canEdit && modelBean.canEditPricing && !cellHasData"
                class="add-button aw-button aw-button--outline"
                (click)="editAdjustment()"
            >
                <svg class="c-svgIcon">
                    <title>Add Adjustment</title>
                    <use
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#plus"
                    ></use>
                </svg>
                <span>Add</span>
            </button>
        </ng-container>
        <!-- Occurrence adjustment - just display total adjustments for the occurrence --->
        <ng-container *ngIf="modelBean.subtotal">
            <div>{{ modelBean.subtotal.occurrenceAdjustments | currency }}</div>
        </ng-container>

        <s25-ng-modal #pricingAdjModal [size]="'xs'">
            <ng-template #s25ModalBody>
                <s25-ng-modal-pricing-adjustment [data]="modalData"></s25-ng-modal-pricing-adjustment>
            </ng-template>
        </s25-ng-modal>
    </div>`,
    styles: `
        html.nm-party--on :host ::ng-deep .edit-icon {
            color: #6faee2;
        }

        .edit-icon {
            position: relative;
            bottom: 2px;
            left: 20px;
            color: #2573a7;
        }

        .adj-item-wrapper {
            width: 7em;
        }

        .adj-item-wrapper:hover {
            cursor: pointer;
        }

        .adj-item-wrapper:hover,
        .adj-item-wrapper:focus {
            outline: 2px solid #2573a7;
            outline-offset: 2px;
            border-radius: 3px;
        }

        html.nm-party--on :host ::ng-deep .adj-item-wrapper:hover,
        .adj-item-wrapper:focus {
            outline-color: #6faee2;
        }

        :host ::ng-deep button svg {
            position: relative;
            right: 2px;
            bottom: 1px;
        }

        .adj-item-wrapper:hover,
        .adj-item-wrapper:focus {
            outline: 2px solid #2573a7;
            outline-offset: 2px;
            border-radius: 3px;
        }

        :host ::ng-deep button svg {
            position: relative;
            right: 2px;
            bottom: 1px;
        }

        :host ::ng-deep button span {
            font-size: 13px;
            position: relative;
            top: 1px;
            right: 2px;
        }

        .adj-item {
            text-wrap: balance;
        }

        :host ::ng-deep .c-svgIcon {
            position: relative;
            bottom: 1px;
        }

        .pricing-tooltip-wrapper {
            display: flex;
            position: relative;
        }

        .tooltip-message {
            opacity: 0;
            transition:
                opacity 1s,
                color 0s;
            position: absolute;
            bottom: 15%;
            left: 10.9em;
            width: 15em;
            font-size: 0.9em;
            color: #fff;
            z-index: -1;
        }

        .tooltip-message > p {
            text-wrap: balance;
        }

        .tooltip-message.hide-tooltip {
            opacity: 1;
            background-color: #fff;
            z-index: 1;
            padding: 10px;
            border: 1px solid darkgrey;
            border-radius: 5px;
            position: absolute;
            bottom: -3.5em;
            left: 8.75em;
            width: 18em;
            font-size: 0.9em;
            color: #000;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        }

        .tooltip-message.hide-tooltip::before,
        .tooltip-message.hide-tooltip::after {
            content: "";
            width: 0;
            height: 0;
            border-top: 7px solid transparent;
            border-right: 15px solid #fff;
            border-bottom: 7px solid transparent;
            display: inline-block;
            position: absolute;
            left: -14px;
            top: 13px;
        }

        .tooltip-message.hide-tooltip::before {
            border-right: 16px solid #000;
            left: -16px;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25PricingAdjustmentComponent implements OnInit {
    @Input() modelBean: any;

    @Output() updateAdjustment: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild("pricingAdjModal") pricingAdjModal: S25ModalComponent;

    init: boolean;
    dollarAdjustments: LineItemI[] = [];
    percAdjustments: LineItemI[] = [];
    cellHasData: boolean;
    sumProfileOccAdjustments: number;
    showTooltip: boolean;
    tooltipMessage: string = "Total of organization adjustments minus occurrence adjustments";
    tooltipHeader = "Total Profile Adjustments";
    modalData: ModalData & { adjustment: LineItemI & PricingOccurrence };

    constructor(private cd: ChangeDetectorRef) {}

    ngOnInit() {
        this.cellHasData =
            this.modelBean.adjustment_percent || this.modelBean.adjustment_amt || this.modelBean.adjustment_name;

        this.modelBean.adjustment_amt &&= parseFloat(this.modelBean.adjustment_amt.toString()).toFixed(2);

        // standardize adjustment_percent display
        if (
            this.modelBean.adjustment_percent &&
            (typeof this.modelBean.adjustment_percent === "number" ||
                this.modelBean.adjustment_percent[this.modelBean.adjustment_percent.length - 1] !== "%")
        ) {
            this.modelBean.adjustment_percent = `${(
                parseFloat(this.modelBean.adjustment_percent.toString()) * 100
            ).toFixed(2)}%`;
        }

        if (this.modelBean.adjustmentType === 2 && this.modelBean.adjustment_percent) {
            this.modelBean.adjustment_percent = `${this.modelBean.adjustmentPercent.toString()}.00%`;
        }

        this.modelBean.onSave = this.onUpdate.bind(this);

        // process footer adjustments
        if (this.modelBean.allBillItems?.length > 0) {
            if (this.modelBean.occView) {
                const nonOccAdjustments = PricingService.agg.adjustments.subtotal(
                    this.modelBean.noOccItems.filter((item: LineItemI) => item.bill_item_type_id !== 2),
                );

                this.sumProfileOccAdjustments =
                    this.modelBean.profileSubtotals.reduce(
                        (sum: number, item: LineItemI) => sum + item.profileAdjustments,
                        0,
                    ) -
                    nonOccAdjustments -
                    this.modelBean.profileSubtotals.reduce(
                        (sum: number, item: LineItemI) => sum + item.occurrenceAdjustments,
                        0,
                    );
            }
            jSith.forEach(this.modelBean.allBillItems, (_, billItem) => {
                if (billItem.bill_item_type_id === -1) {
                    if (
                        billItem.adjustment_percent ||
                        (billItem.adjustment_percent === 0 && S25Util.isUndefined(billItem.adjustment_amt)) //ANG-4959 Lets any comment be edited while staying type consistent
                    ) {
                        if (typeof billItem.adjustment_percent === "number" || billItem.evBillId) {
                            billItem.adjustment_percent = `${(
                                parseFloat(billItem.adjustment_percent.toString()) * 100
                            ).toFixed(2)}%`;
                        }
                        this.percAdjustments.push(billItem);
                    } else if (billItem.adjustment_amt) {
                        this.dollarAdjustments.push(billItem);
                    } else {
                        //ANG-4959 allow editing of any stray adjustments they may show up in other places
                        billItem.adjustment_amt = 0;
                        this.dollarAdjustments.push(billItem);
                    }
                }
            });
        }

        this.init = true;
    }

    editAdjustment(billItem?: any, isNew?: boolean) {
        if (isNew) {
            const { eventId, canEdit, canEditPricing, orgId, evBillId } = this.modelBean;

            billItem = {
                ...billItem,
                eventId,
                canEdit,
                canEditPricing,
                charge_to_id: orgId,
                evBillId,
            };
        }

        if (billItem && !billItem.eventId) billItem.eventId = this.modelBean.eventId;

        if (this.modelBean.profileId) this.modelBean.isNew = !this.cellHasData;

        this.modalData = {
            adjustment: billItem
                ? Object.assign(billItem, { adjustmentType: 99, isNew, onSave: this.modelBean.onSave })
                : this.modelBean,
        };
        this.cd.detectChanges();
        return this.pricingAdjModal.open();
    }

    onUpdate(pricingData: any) {
        const updatedRow = PricingService.processTableUpdate(pricingData, this.modelBean);
        this.updateAdjustment.emit(updatedRow);
        return this.pricingAdjModal.close();
    }

    toggleTooltip() {
        this.showTooltip = !this.showTooltip;
        this.cd.detectChanges();
    }

    protected readonly PricingService = PricingService;
}
