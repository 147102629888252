import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    Renderer2,
} from "@angular/core";
import { LineItemI } from "../../../../pojo/Pricing";
import { TypeManagerDecorator } from "../../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-pricing-item")
@Component({
    selector: "s25-ng-pricing-item",
    template: `<div *ngIf="init" class="pricing-item-wrapper">
        <span *ngIf="!modelBean.reservations?.length && modelBean.total_charge">{{ modelBean.itemName }}</span>

        <div
            *ngIf="!modelBean.total_charge && !modelBean.reservations?.length"
            tabindex="0"
            aria-label="Hide Line Item Row"
            class="line-item-name"
            (mouseenter)="toggleHideMessage()"
            (mouseleave)="toggleHideMessage()"
            (focus)="toggleHideMessage()"
            (focusout)="toggleHideMessage()"
            (click)="handleRowVisibility($event)"
            (keydown.enter)="handleRowVisibility($event)"
        >
            <span class="pricing-item">{{ modelBean.itemName }}</span>
            <span class="c-margin-left--quarter">
                <svg class="c-svgIcon">
                    <title>Hide Line Item Row</title>
                    <use
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#no"
                    ></use>
                </svg>
            </span>
        </div>
        <div class="row-message" [ngClass]="{ 'hide-message': showHideMessage }">Items totalling $0 can be hidden</div>

        <button
            *ngIf="modelBean.reservations?.length > 0"
            class="c-textButton occ-expand-button-{{ modelBean.charge_to_id }}"
            (click)="handleShowToggle($event)"
            aria-label="Expand or Collapse Reservations"
        >
            {{ modelBean.itemName }}
        </button>
        <span *ngIf="modelBean.reservations?.length > 0">
            <svg *ngIf="toggleShowOccData" class="c-svgIcon">
                <title>Hide Reservations</title>
                <use
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#chevron-up"
                ></use>
            </svg>
            <svg *ngIf="!toggleShowOccData" class="c-svgIcon">
                <title>View Reservations</title>
                <use
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#chevron-down"
                ></use>
            </svg>
        </span>
    </div>`,
    styles: `
        :host ::ng-deep .c-textButton {
            padding: unset;
            width: 9.2em;
            text-wrap: balance;
        }

        :host ::ng-deep .c-svgIcon {
            position: relative;
            bottom: 1px;
            filter: invert(33%) sepia(86%) saturate(469%) hue-rotate(160deg) brightness(101%) contrast(87%);
        }

        .pricing-item-wrapper {
            display: flex;
            position: relative;
        }

        .line-item-name:hover {
            cursor: pointer;
        }

        .line-item-name:focus {
            outline: 2px solid #2573a7;
            outline-offset: 2px;
            border-radius: 3px;
        }

        .row-message {
            opacity: 0;
            transition:
                opacity 1s,
                color 0s;
            position: absolute;
            bottom: 15%;
            left: 10.9em;
            width: 15em;
            font-size: 0.9em;
            color: #fff;
            z-index: -1;
        }

        .row-message.hide-message {
            opacity: 1;
            background-color: #fff;
            z-index: 1;
            padding: 10px;
            border: 1px solid darkgrey;
            border-radius: 5px;
            position: absolute;
            bottom: -2em;
            left: 9em;
            width: 15em;
            font-size: 0.9em;
            color: #000;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        }

        .row-message.hide-message::before,
        .row-message.hide-message::after {
            content: "";
            width: 0;
            height: 0;
            border-top: 7px solid transparent;
            border-right: 15px solid #fff;
            border-bottom: 7px solid transparent;
            display: inline-block;
            position: absolute;
            left: -15px;
            top: 13px;
        }

        .row-message.hide-message::before {
            border-right: 16px solid #000;
            left: -16px;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25PricingItemComponent implements OnInit {
    @Input() modelBean: any;

    @Output() showOccData: EventEmitter<OccurrenceTable> = new EventEmitter<OccurrenceTable>();
    @Output() signalHiddenRows: EventEmitter<HTMLTableElement> = new EventEmitter<HTMLTableElement>();

    init: boolean;
    toggleShowOccData: boolean = false;
    showHideMessage: boolean;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private renderer: Renderer2,
    ) {}

    ngOnInit() {
        this.init = true;
    }

    handleShowToggle(event: any) {
        const tableEl = event.target.offsetParent.parentElement.offsetParent.offsetParent;
        const rowEl = event.target.offsetParent.parentElement.offsetParent.parentElement;

        const rows = Array.from(tableEl.children[2].children);
        const rowIndex = rows.indexOf(rowEl);

        const tableData: OccurrenceTable = {
            table: tableEl,
            row: rowEl,
            rowIndex: rowIndex + 1,
            action: this.toggleShowOccData ? "delete" : "create",
            data: this.modelBean,
        };

        this.toggleShowOccData = !this.toggleShowOccData;
        this.cd.detectChanges();
        this.showOccData.emit(tableData);
    }

    toggleHideMessage() {
        this.showHideMessage = !this.showHideMessage;
        this.cd.detectChanges();
    }

    handleRowVisibility(event: any) {
        const itemNameEl = this.elementRef.nativeElement.querySelector(".pricing-item");

        const rowToProcess = itemNameEl.parentElement.parentElement.parentElement.offsetParent.parentElement;

        const table = itemNameEl.offsetParent.parentElement.offsetParent.offsetParent;

        if (rowToProcess) this.renderer.addClass(rowToProcess, "ngHidden");

        this.signalHiddenRows.emit(table);
    }
}

export type OccurrenceTable = {
    table: HTMLTableElement;
    row: HTMLTableRowElement;
    rowIndex: number;
    action: "create" | "delete";
    data: LineItemI;
};
