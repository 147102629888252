import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { PricingOccurrence } from "../../../../pojo/Pricing";
import { Table } from "../../../s25-table/Table";
import { TypeManagerDecorator } from "../../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-pricing-occurrences")
@Component({
    selector: "s25-ng-pricing-occurrences",
    template: `<div *ngIf="init" class="res-table">
        <s25-ng-table [dataSource]="tableConfig" [pivotThresholdColumn]="null"></s25-ng-table>
    </div>`,
    styles: `
        :host ::ng-deep .s25ngTable {
            margin-right: unset;
            margin-left: 5px;
        }

        :host ::ng-deep .s25-ng-table--table tbody tr:nth-last-child(3) {
            border-top: unset;
        }

        :host ::ng-deep .s25-ng-table--table tbody tr:nth-last-child(3) > td {
            padding-top: 0.5em !important;
        }

        :host ::ng-deep .s25-ng-table--table tbody tr:nth-last-child(4) > td {
            padding-bottom: 0.5em !important;
        }

        :host ::ng-deep .s25-ng-table--table .tableHeader {
            visibility: collapse;
        }

        :host ::ng-deep .res-table {
            box-shadow:
                inset 0px 11px 8px -10px #ccc,
                inset 0px -11px 8px -10px #ccc;
        }

        :host ::ng-deep s25-ng-table tbody tr:nth-child(2n + 2) {
            background-color: unset;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25PricingOccurrencesComponent implements OnInit {
    @Input() data: any;

    init: boolean;
    tableConfig: Table.DataSource;

    constructor(private cd: ChangeDetectorRef) {}

    ngOnInit() {
        this.data.occData.reservations?.map((occ: PricingOccurrence) => (occ.listPrice ??= 0));
        this.data.occData.isOccurrence = true;

        this.initTableConfig();

        this.init = true;
        this.cd.detectChanges();
    }

    initTableConfig() {
        const promise = new Promise((resolve, _) => {
            const data = [...this.data.rowFunc(this.data.occData)];

            resolve(data);
        });

        const getData = async () => {
            const data: any = await promise;

            return {
                rows: data.map(this.data.mapToRows),
            };
        };

        this.tableConfig = {
            type: "unpaginated",
            dataSource: getData,
            columns: this.data.columns,
        };
    }
}
