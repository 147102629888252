import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from "@angular/core";
import { Table } from "../../../s25-table/Table";
import { S25TableComponent } from "../../../s25-table/s25.table.component";
import { S25PricingTaxComponent } from "../s25-pricing-orgs/s25.pricing.tax.component";
import { PricingService } from "../../../../services/pricing.service";
import { TypeManagerDecorator } from "../../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-pricing-totals")
@Component({
    selector: "s25-ng-pricing-totals",
    template: `<div *ngIf="init">
        <s25-ng-table [caption]="'Pricing Totals'" [dataSource]="tableConfig"></s25-ng-table>
    </div>`,
    styles: `
        :host ::ng-deep td {
            vertical-align: top !important;
        }

        :host ::ng-deep .s25-ng-table--table {
            max-width: unset;
        }

        :host ::ng-deep .s25ngTable {
            max-width: unset;
            margin-right: 1em;
        }

        :host ::ng-deep .s25ngTable table {
            max-width: unset;
        }

        :host ::ng-deep .s25-ng-table--table tbody tr:last-child {
            border-top: 2px solid #555;
        }

        :host ::ng-deep .s25-ng-table--table tbody tr:last-child > td {
            padding-top: 1em !important;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25PricingTotalsComponent implements OnInit {
    @Input() modelBean: any;

    @ViewChild(S25TableComponent) totalsTable: S25TableComponent;

    init: boolean;
    columns: Table.Column[] = [
        { id: "item", header: "Item" },
        { id: "list_price", header: "List Price" },
        { id: "adjustments", header: "Adjustments" },
        { id: "price", header: "Price" },
        { id: "taxes", header: "Taxes" },
        { id: "total", header: "Total" },
        { id: "charge_to", header: "Charge To" },
    ];
    tableConfig: Table.DataSource;

    constructor() {}

    ngOnInit() {
        this.initTableConfig();
        this.init = true;
    }

    initTableConfig(updateData?: any) {
        let tableNeedsRefresh;
        if (updateData) {
            const chargeToNameMap: any = {};

            this.modelBean.rows.forEach((row: any) => {
                chargeToNameMap[row.charge_to_id] = row.charge_to_name;
            });

            const eventsTotal = updateData.subtotals.reduce((acc: number, row: any) => {
                return acc + row.eventsTotalCharge;
            }, 0);

            const lineItemRows = updateData.subtotals.map((row: any) => {
                return {
                    adjustment_amt: row.profileAdjustments + row.requirementsAdjustments,
                    charge_to_id: row.chargeToId,
                    charge_to_name: chargeToNameMap[row.chargeToId],
                    list_price: row.occurrenceListPrice + row.requirementsListPrice,
                    taxable_amt: row.taxableAmount,
                    total_tax: row.tax,
                    total_charge: row.eventsTotalCharge,
                };
            });

            const adjustmentRows =
                updateData.adjustments?.map((row: any) => {
                    return {
                        adjustment_amt: row.adjustmentAmt,
                        adjustment_percent: row.adjustmentPercent / 100,
                        adjustment_name: row.adjustmentName,
                        charge_to_id: row.chargeToId,
                        charge_to_name: chargeToNameMap[row.chargeToId],
                        total_charge: row.totalCharge,
                        isAdjustment: true,
                        evBillId: row.evBillId,
                    };
                }) ?? [];

            this.modelBean.rows = [...lineItemRows, ...adjustmentRows];

            this.modelBean.grandAdjustmentAmt = this.modelBean.rows.reduce((acc: number, row: any) => {
                const amount = row.adjustment_amt ?? eventsTotal * row.adjustment_percent;
                return acc + amount;
            }, 0);

            const { occurrenceListPrice, requirementsListPrice, taxableAmount, grandTotal } = updateData.totals;

            this.modelBean.grandPriceList = occurrenceListPrice + requirementsListPrice;

            this.modelBean.grandTaxableAmt = taxableAmount;

            this.modelBean.grandTaxes.tax = Object.values(updateData.taxData);

            this.modelBean.grandTotalCharge = grandTotal;

            tableNeedsRefresh = true;
        }

        const promise = new Promise((resolve, _) => {
            const { grandAdjustmentAmt, grandPriceList, grandTaxableAmt, grandTaxes, grandTotalCharge } =
                this.modelBean;

            let data = [
                ...this.modelBean.rows,
                { grandAdjustmentAmt, grandPriceList, grandTaxableAmt, grandTaxes, grandTotalCharge },
            ];

            if (this.modelBean.rows.length === 0) data = [];

            resolve(data);
        });

        const getData = async () => {
            const data: any = await promise;

            return {
                rows: data.map(this.mapToRows),
            };
        };

        this.tableConfig = {
            type: "unpaginated",
            dataSource: getData,
            columns: this.columns,
        };

        if (tableNeedsRefresh) {
            this.totalsTable.dataSource = this.tableConfig;
            this.totalsTable.refresh();
        }
    }

    mapToRows(item: any): Table.Row {
        return {
            id: "Pricing Totals",
            name: "PricingTotals",
            cells: {
                item: { text: item.isAdjustment ? "Adjustment:" : item.charge_to_name ? "Subtotals:" : "Grand Total" },
                list_price: {
                    text: item.list_price
                        ? PricingService.formatCurrency(item.list_price)
                        : item.grandPriceList
                          ? PricingService.formatCurrency(item.grandPriceList)
                          : item.adjustment_name,
                },
                adjustments: {
                    text: item.adjustment_amt
                        ? PricingService.formatCurrency(item.adjustment_amt)
                        : item.adjustment_percent
                          ? `${item.adjustment_percent * 100}%`
                          : item.grandAdjustmentAmt
                            ? PricingService.formatCurrency(item.grandAdjustmentAmt)
                            : "",
                },
                price: {
                    text: item.taxable_amt
                        ? PricingService.formatCurrency(item.taxable_amt)
                        : item.grandTaxableAmt
                          ? PricingService.formatCurrency(item.grandTaxableAmt)
                          : "",
                },
                taxes: {
                    ...(item.total_tax
                        ? { text: PricingService.formatCurrency(item.total_tax) }
                        : item.grandTaxes && {
                              component: S25PricingTaxComponent,
                              inputs: { taxData: item.grandTaxes.tax },
                          }),
                },
                total: {
                    text: item.total_charge
                        ? PricingService.formatCurrency(item.total_charge)
                        : item.grandTotalCharge
                          ? PricingService.formatCurrency(item.grandTotalCharge)
                          : "",
                },
                charge_to: {
                    text: item.charge_to_name ?? "",
                },
            },
        };
    }
}
